var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Inventory" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 16 } },
                                        [
                                          _c("InputSearch", {
                                            staticStyle: { width: "98%" },
                                            on: {
                                              "input-search":
                                                _vm.reponseSearchInput
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.ResetFilter(true)
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              cursor: "pointer",
                                              fontsize: "large",
                                              transform: "scaleX(-1)",
                                              "-moz-transform": "scaleX(-1)",
                                              "-webkit-transform": "scaleX(-1)",
                                              "-ms-transform": "scaleX(-1)"
                                            },
                                            attrs: { type: "reload" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  idtable: "table1",
                                  onSelectChange: null,
                                  selectedRowKeys: null,
                                  dataSource: _vm.dataListInventory.data,
                                  columns: _vm.columnsTable,
                                  scroll: { x: null },
                                  paginationcustom: true,
                                  defaultPagination: false
                                },
                                on: {
                                  "on-delete": _vm.reponseDeleteTable,
                                  "on-edit": _vm.reponseEditTable,
                                  "on-tablechange": _vm.onSorterChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total:
                                        _vm.dataListInventory.totalElements,
                                      pageSizeSet: _vm.limit,
                                      idPagination: "pagination1"
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 520,
            title: _vm.titlemodalOpen,
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c("div", { attrs: { slot: "contentmodal" }, slot: "contentmodal" }),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" Cancel ")
              ]),
              _vm.typemodal === "create" || _vm.typemodal === "edit"
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.isFormSubmitted,
                        "html-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitForm($event, _vm.typemodal)
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }