

































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { logisticServices } from "@/services/logistic.service";
import { ResponseListOfInventory} from "@/models/interface/logistic.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
export default Vue.extend({
  
  data() {
    return {
      dataListInventory: {} as ResponseListOfInventory,
      direction: "" as string,
      trigerdisable: false as boolean,
      typemodal: "" as string,
      titlemodalOpen: "Inventory" as string,
      switchActive: true as boolean,
      switchActivebase:true as boolean,
      authPrivilege: [] as string[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "inventory" }),
      formRules: {
        product: {
          label: "Product",
          name: "product",
          placeholder: "Insert product",
          decorator: [
            "product",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        uom: {
          label: "Uom",
          name: "uom",
          placeholder: "Insert uom",
          decorator: [
            "uom",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        onhand: {
          label: "On Hand",
          name: "onhand",
          placeholder: "Insert onhand",
          decorator: [
            "onhand",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        available: {
          label: "Available",
          name: "available",
          placeholder: "Insert available",
          decorator: [
            "available",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        reserved: {
          label: "Reserved",
          name: "reserved",
          placeholder: "Insert reserved",
          decorator: [
            "reserved",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        ontransit: {
          label: "On Transit",
          name: "ontransit",
          placeholder: "Insert ontransit",
          decorator: [
            "ontransit",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        defect: {
          label: "Defect",
          name: "defect",
          placeholder: "Insert defect",
          decorator: [
            "defect",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
          {
          title: "Gudang",
          dataIndex: "warehouseLocationId",
          key: "gudang",
          sorter: true,
          width: 100,
        },
        {
          title: "Product",
          dataIndex: "productId",
          key: "product",
          sorter: true,
          width: 100,
        },
        {
          title: "Uom",
          dataIndex: "uomId",
          key: "uom",
          sorter: true,
          width: 100,
        },
        {
          title: "On Hand",
          dataIndex: "onHand",
          key: "onhand",
          sorter: true,
          width: 100,
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          sorter: true,
          width: 100,
        },
        {
          title: "Reserved",
          dataIndex: "onReserve",
          key: "reserved",
          sorter: true,
          width: 100,
        },
        {
          title: "On Transit",
          dataIndex: "onTransit",
          key: "ontransit",
          sorter: true,
          width: 100,
        },
        {
          title: "Defect",
          dataIndex: "defect",
          key: "defect",
          sorter: true,
          width: 100,
        },
      ] as any,
    };
  },
  mounted(){
    this.getListOfInventory("")
  },
  methods: {
      onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfInventory("");
    },
    reponseSearchInput(response: any): void {
      if (response)
        this.search =
          "product~*" + response + "*";
      else this.search = "";
      this.getListOfInventory("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfInventory("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfInventory("");
    },
    reponseEditTable(response: any): void {
      console.log(response);
      
    },
    reponseDeleteTable(response: any): void {
      console.log(response);
      
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    handleSubmit(e: Event): void {
      e.preventDefault();
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
    },
    submitForm(e: Event, type: string): void {
      e.preventDefault();
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
getListOfInventory(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      logisticServices
        .listOfInventory(params)
        .then((res: any) => {
          if (!path) {
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListInventory = res;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    ResetFilter(getlist: any): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfInventory("");
    },    
  },
});
